import React from "react";
import ContactMe from "../components/ContactMe.jsx";

const ContactMePage = () => {
  return (
    <>
      <ContactMe />
    </>
  );
};

export default ContactMePage;
