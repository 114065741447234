import React from "react";
import Experience from "../components/Experience";

const ExperiencePage = () => {
  return (
    <>
      <Experience />
    </>
  );
};

export default ExperiencePage;
