import React from "react";
import Skill from "../components/Skill";

const SkillPage = () => {
  return (
    <>
      <Skill />
    </>
  );
};

export default SkillPage;
